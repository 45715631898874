<template>
  <v-container id="Collections" fluid tag="section">
    <v-row>
      <v-col cols="12">
        <v-container class="py-0">
          <form
            novalidate
            @submit.prevent="getCollections"
            @md-changed="scrollToTop"
          >
            <md-card-content class="search-content">
              <v-row class="collection-search-bar">
                <v-col xl="3" lg="3" xs="6">
                  <md-field class="search_field_class">
                    <label for="search-query">Search</label>
                    <md-input
                      v-model="query"
                      name="search"
                      id="search"
                      @input="debounceSearch"
                    />
                  </md-field>
                </v-col>
                <v-col xl="3" lg="3" xs="6" v-if="!getIsSuperAdmin()">
                  <md-field class="search_field_class">
                    <label for="collection_type">Collection</label>
                    <md-select
                      @input="getCollections"
                      data-cy="collection-type"
                      name="collection-type"
                      id="collection-type"
                      v-model="collectionType"
                      placeholder="Collections"
                      :disabled="loading"
                    >
                      <md-option class="list_color" value="all"
                        >All collections</md-option
                      >
                      <md-option
                        class="list_color"
                        value="own"
                        v-if="
                          getIsBrandAdmin() ||
                          getIsSupplierAdmin() ||
                          getIsSupplierAccountManager() ||
                          getIsSupplierUser()
                        "
                        >Own collections</md-option
                      >
                      <md-option
                        class="list_color"
                        value="created_by_me"
                        v-if="
                          getIsBrandAdmin() ||
                          getIsBrandAccountManager() ||
                          getIsBrandUser()
                        "
                        >Created by me</md-option
                      >
                      <md-option
                        class="list_color"
                        value="created_by_others"
                        v-if="
                          getIsBrandAdmin() ||
                          getIsBrandAccountManager() ||
                          getIsBrandUser()
                        "
                        >Created by others</md-option
                      >
                      <md-option
                        class="list_color"
                        value="created_for_others"
                        v-if="
                          getIsSupplierAdmin() ||
                          getIsSupplierAccountManager() ||
                          getIsSupplierUser()
                        "
                        >Created for others</md-option
                      >
                      <md-option
                        class="list_color"
                        value="assigned_by_others"
                        v-if="
                          getIsSupplierAdmin() ||
                          getIsSupplierAccountManager() ||
                          getIsSupplierUser()
                        "
                        >Added by others</md-option
                      >
                    </md-select>
                  </md-field>
                </v-col>

                <v-col
                  xl="3"
                  lg="3"
                  xs="6"
                  v-if="collectionType === 'created_for_others'"
                >
                  <!-- <md-field class="search_field_class">
                    <label for="brands">Brands</label>
                    <md-select
                      @input="getCollections"
                      data-cy="brands"
                      name="brands"
                      id="brands"
                      v-model="selectedBrand"
                      placeholder="Select Brand"
                      :disabled="loading"
                    >
                    </md-select>
                  </md-field> -->
                </v-col>
                <v-col lg="3" xs="6">
                  <v-btn
                    class="ma-2 mt-3 color_class"
                    dark
                    outlined
                    id="clear-filters"
                    :disabled="loading"
                    @click="clearFilters"
                  >
                    Clear filters
                  </v-btn>
                </v-col>
              </v-row>
            </md-card-content>
          </form>

          <div v-if="searching" class="my-5">
            <md-progress-spinner
              class="color_stroke"
              md-mode="indeterminate"
            ></md-progress-spinner>
          </div>

          <div v-for="collection in collections" :key="collection.id">
            <collection-list :collection="collection" />
          </div>
          <div v-if="loadingPage" class="my-5">
            <md-progress-spinner
              class="color_stroke"
              md-mode="indeterminate"
            ></md-progress-spinner>
          </div>
          <Pagination
            style="margin: -9px"
            :current-page="currentPage"
            :total-count="totalCount"
            :per-page-count="10"
            @load-page="loadPage"
            v-if="collections.length"
          />
          <v-row
            v-if="
              !loading && !loadingPage && !searching && collections.length === 0
            "
          >
            <v-col cols="12"> No Collections found. </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import restAdapter from "../../restAdapter";
import auth from "../../auth";
import Pagination from "../../components/Pagination";
import * as conf from "../../conf.yml";
import debounce from "lodash/debounce";
import utils from "../../utils";
import CollectionList from "../collection/CollectionList.vue";

export default {
  name: "CollectionListActive",
  components: {
    Pagination,
    CollectionList,
  },
  data: () => ({
    loading: false,
    loadingPage: false,
    searching: false,
    collectionType: "all",
    query: "",
    sampleImage: require(`../../assets/collection.png`),
    currentPage: 1,
    totalCount: 0,
    acceptedThumbnailTypes: utils.acceptedThumbnailTypes,
    collections: [],
  }),

  mounted() {
    this.baseUrl = conf.default.server.host;
    this.collectionType = this.$route.query.collection_type
      ? this.$route.query.collection_type
      : "all";
    this.query = this.$route.query.query ? this.$route.query.query : " ";
    this.getCollections();
    this.scrollToTop();
  },

  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    getIsSupplierAccountManager() {
      return auth.user.role["name"] === "supplier_account_manager";
    },
    getIsSuperAdmin() {
      return auth.user.role["name"] === "super_admin";
    },
    getIsBrandAccountManager() {
      return auth.user.role["name"] === "brand_account_manager";
    },
    getIsBrandAdmin() {
      return auth.user.role["name"] === "brand_admin";
    },
    getIsSupplierAdmin() {
      return auth.user.role["name"] === "supplier_admin";
    },
    getIsBrandUser() {
      return auth.user.role["name"] === "brand_user";
    },
    getIsSupplierUser() {
      return auth.user.role["name"] === "supplier_user";
    },
    async getCollections() {
      this.searching = true;

      try {
        const response = await restAdapter.get(
          `/api/collections?page=${this.currentPage}&query=${this.query}&collection_type=${this.collectionType}`
        );

        if (response.data) {
          this.collections = response.data.data;
          this.totalCount = response.data.meta.total;
        } else {
          this.collections = [];
          this.totalCount = 0;
        }
      } finally {
        this.searching = false;
        this.loadingPage = false;
      }
    },

    loadPage(page) {
      this.currentPage = page;
      this.loadingPage = true;
      this.getCollections().then(() => {
        this.scrollToTop();
      });
    },

    clearFilters() {
      this.collectionType = "all";
      this.query = "";

      this.$router.push({
        path: "collections",
        query: {
          /* eslint-disable-next-line @typescript-eslint/camelcase */
          collection_type: this.collectionType,
          query: this.query,
        },
      });
    },

    debounceSearch: debounce(function () {
      this.currentPage = 1;
      this.getCollections();
    }, 500),
  },
};
</script>

<style lang="scss" scoped>
.grid-2 {
  display: flex;
  flex-direction: row;
}

.collection-search-bar {
  text-align: right;
  // justify-content: flex-end;
  justify-content: left;
  background: #ffffff;
  box-shadow: 0px 3px 6px rgba(71, 48, 104, 0.06);
  border-radius: 7px;
}

.search-content {
  margin-bottom: 40px;
  padding: 12px;
}

.search_field_class {
  margin: 0%;
}

.collection-style-number {
  color: #f3f1f1;
  text-align: left;
  font-family: sans-serif;
  font-weight: 500;
}

.collection-type {
  color: #f3f1f1;
}

.date {
  color: #f3f1f1;
  text-align: right;
}

.color_class {
  border: 1px solid #473068;
  color: #442d65 !important;
}

.color_stroke::v-deep .md-progress-spinner-circle {
  stroke: #492a67 !important;
}

.md-field.md-theme-default:before {
  background-color: #473068 !important;
}

.list_color.md-selected::v-deep .md-list-item-text {
  color: #473068 !important;
}

.md-field.md-theme-default.md-focused label {
  color: #442d65 !important;
}

.search_type_container {
  margin-top: -10px !important;
  background-color: #f3edf7 !important;
  box-shadow: 3.19039px 14.8885px 12.7616px rgba(0, 0, 0, 0.1) !important;
  border-radius: 4px !important;
}

.list_color.md-theme-default.md-selected {
  background-color: #e2daed !important;
}
</style>
