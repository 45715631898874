var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"id":"Collections","fluid":"","tag":"section"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-container',{staticClass:"py-0"},[_c('form',{attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return _vm.getCollections.apply(null, arguments)},"md-changed":_vm.scrollToTop}},[_c('md-card-content',{staticClass:"search-content"},[_c('v-row',{staticClass:"collection-search-bar"},[_c('v-col',{attrs:{"xl":"3","lg":"3","xs":"6"}},[_c('md-field',{staticClass:"search_field_class"},[_c('label',{attrs:{"for":"search-query"}},[_vm._v("Search")]),_c('md-input',{attrs:{"name":"search","id":"search"},on:{"input":_vm.debounceSearch},model:{value:(_vm.query),callback:function ($$v) {_vm.query=$$v},expression:"query"}})],1)],1),(!_vm.getIsSuperAdmin())?_c('v-col',{attrs:{"xl":"3","lg":"3","xs":"6"}},[_c('md-field',{staticClass:"search_field_class"},[_c('label',{attrs:{"for":"collection_type"}},[_vm._v("Collection")]),_c('md-select',{attrs:{"data-cy":"collection-type","name":"collection-type","id":"collection-type","placeholder":"Collections","disabled":_vm.loading},on:{"input":_vm.getCollections},model:{value:(_vm.collectionType),callback:function ($$v) {_vm.collectionType=$$v},expression:"collectionType"}},[_c('md-option',{staticClass:"list_color",attrs:{"value":"all"}},[_vm._v("All collections")]),(
                        _vm.getIsBrandAdmin() ||
                        _vm.getIsSupplierAdmin() ||
                        _vm.getIsSupplierAccountManager() ||
                        _vm.getIsSupplierUser()
                      )?_c('md-option',{staticClass:"list_color",attrs:{"value":"own"}},[_vm._v("Own collections")]):_vm._e(),(
                        _vm.getIsBrandAdmin() ||
                        _vm.getIsBrandAccountManager() ||
                        _vm.getIsBrandUser()
                      )?_c('md-option',{staticClass:"list_color",attrs:{"value":"created_by_me"}},[_vm._v("Created by me")]):_vm._e(),(
                        _vm.getIsBrandAdmin() ||
                        _vm.getIsBrandAccountManager() ||
                        _vm.getIsBrandUser()
                      )?_c('md-option',{staticClass:"list_color",attrs:{"value":"created_by_others"}},[_vm._v("Created by others")]):_vm._e(),(
                        _vm.getIsSupplierAdmin() ||
                        _vm.getIsSupplierAccountManager() ||
                        _vm.getIsSupplierUser()
                      )?_c('md-option',{staticClass:"list_color",attrs:{"value":"created_for_others"}},[_vm._v("Created for others")]):_vm._e(),(
                        _vm.getIsSupplierAdmin() ||
                        _vm.getIsSupplierAccountManager() ||
                        _vm.getIsSupplierUser()
                      )?_c('md-option',{staticClass:"list_color",attrs:{"value":"assigned_by_others"}},[_vm._v("Added by others")]):_vm._e()],1)],1)],1):_vm._e(),(_vm.collectionType === 'created_for_others')?_c('v-col',{attrs:{"xl":"3","lg":"3","xs":"6"}}):_vm._e(),_c('v-col',{attrs:{"lg":"3","xs":"6"}},[_c('v-btn',{staticClass:"ma-2 mt-3 color_class",attrs:{"dark":"","outlined":"","id":"clear-filters","disabled":_vm.loading},on:{"click":_vm.clearFilters}},[_vm._v(" Clear filters ")])],1)],1)],1)],1),(_vm.searching)?_c('div',{staticClass:"my-5"},[_c('md-progress-spinner',{staticClass:"color_stroke",attrs:{"md-mode":"indeterminate"}})],1):_vm._e(),_vm._l((_vm.collections),function(collection){return _c('div',{key:collection.id},[_c('collection-list',{attrs:{"collection":collection}})],1)}),(_vm.loadingPage)?_c('div',{staticClass:"my-5"},[_c('md-progress-spinner',{staticClass:"color_stroke",attrs:{"md-mode":"indeterminate"}})],1):_vm._e(),(_vm.collections.length)?_c('Pagination',{staticStyle:{"margin":"-9px"},attrs:{"current-page":_vm.currentPage,"total-count":_vm.totalCount,"per-page-count":10},on:{"load-page":_vm.loadPage}}):_vm._e(),(
            !_vm.loading && !_vm.loadingPage && !_vm.searching && _vm.collections.length === 0
          )?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_vm._v(" No Collections found. ")])],1):_vm._e()],2)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }